<template>
    <div>
        <v-card-title>
            <v-checkbox label="Показать скрытые новости" v-model="onlyHidden" @change="getList()"></v-checkbox>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="query"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                @input="search"
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="-1"
            class="elevation-1"
            :loading="loading"
            :options.sync="options"
            page.sync="1"
            loading-text="Загрузка..."
            :server-items-length="totalCount"
            :footer-props="{
            itemsPerPageOptions: [10, 50, 100, 150],
            disableItemsPerPage: false,
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все'
            }"
        >
            <template #[`item.postpone`]="{ item }">
                <v-simple-checkbox
                    v-model="item.postpone"
                    disabled
                ></v-simple-checkbox>
            </template>

            <template #[`item.indexShow`]="{ item }">
                <v-simple-checkbox
                    v-model="item.indexShow"
                    disabled
                ></v-simple-checkbox>
            </template>

            <template v-slot:top>
                <v-dialog
                v-model="dialogEdit"
                persistent
                max-width="900px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        >
                        Добавить новость
                        </v-btn>
                    </template>
                    
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-tabs v-model="curTab" background-color="transparent" color="basil" grow>
                            <v-tab>Основное</v-tab>
                            <v-tab>Контент</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="curTab">
                            <v-tab-item>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-text-field
                                            required
                                            label="Заголовок"
                                            autocomplete="nope"
                                            v-model="editedItem.name"
                                        ></v-text-field>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-textarea v-model="editedItem.description" label="Краткое описание" hint="*Не больше 135 символов"></v-textarea>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-select label="Категории" v-model="editedItem.tags" :items="tags" :menu-props="{ maxHeight: '400' }" multiple persistent-hint item-text="name" item-value="id"></v-select>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-file-input label="Картинка анонса(340x210px)" accept="image/jpeg, image/png" :clearable="false" v-model="editedItem.newPreview"></v-file-input>
                                        <div class="d-flex flex-column justify-space-between align-center" v-if="editedItem.preview">
                                            <v-img
                                                :src="$store.state.api + editedItem.preview"
                                                max-height="200"
                                                :contain="true"
                                            />
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-select label="Города" v-model="editedItem.cities" :items="cities" :menu-props="{ maxHeight: '400' }" multiple persistent-hint item-text="name" item-value="id"></v-select>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-checkbox label="Скрыть новость?" v-model="editedItem.hide"></v-checkbox>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-checkbox label="Отложенная публикация" v-model="editedItem.postpone" @change="resetDate()"></v-checkbox>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-checkbox label="Вывести на главной" v-model="editedItem.indexShow"></v-checkbox>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-checkbox label="Топ 10" v-model="editedItem.isTop"></v-checkbox>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="editedItem.postpone" color="basil" flat>
                                    <v-card-text>
                                        <v-dialog
                                            ref="dialog"
                                            v-model="datePubDialog"
                                            :return-value.sync="editedItem.datePub"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="getPostponeDate"
                                                    label="Дата публикации"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                             <v-card color="basil" flat>
                                                <v-card-title>
                                                    <date-picker
                                                        v-model="editedItem.datePub"
                                                        mode="dateTime"
                                                        :timezone="editedItem.timezone"
                                                        :masks='{ input:["L", "YYYY-MM-DD", "YYYY/MM/DD"] }'
                                                        class="date-picker"
                                                        locale="ru-RU"
                                                        is24hr
                                                    />
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="datePubDialog = false"
                                                    >
                                                        Отмена
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.dialog.save(editedItem.datePub)"
                                                    >
                                                        Ок
                                                    </v-btn>
                                                </v-card-text>
                                            </v-card>
                                        </v-dialog>
                                    </v-card-text>
                                </v-card>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-dialog
                                            v-model="dialogSource"
                                            persistent
                                            max-width="600px"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="primary"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="onOpenSource"
                                                >
                                                Источник
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                <span class="text-h5">Источник</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-text-field
                                                            v-model="editedItem.source.text"
                                                            label="Текст ссылки"
                                                            required
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-model="editedItem.source.link"
                                                            label="Ссылка"
                                                            required
                                                        ></v-text-field>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="onCancelSource"
                                                >
                                                    Отмена
                                                </v-btn>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="dialogSource = false"
                                                >
                                                    Сохранить
                                                </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                            </v-dialog>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <content-editor :list.sync="editedItem.content"></content-editor>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEdit"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <file-uploader ref="uploader" />
                </v-dialog>

                <v-dialog
                v-model="dialogRemove"
                max-width="500px"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Удаление новости</span>
                        </v-card-title>

                        <v-card-text>Вы действительно хотите удалить указанную новость?</v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeRemove"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="removeItem(itemToRemove)"
                            >
                                Удалить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            
            <template #[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    title="Изменить новость"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="openRemoveDialog(item)"
                    title="Удалить новость"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template #[`item.hide`]="{ item }"><v-icon small v-if="item.hide">mdi-check-bold</v-icon></template>
        </v-data-table>
    </div>
</template>

<script>
import FileUploader from "@c/FileUploader.vue";
import ContentEditor from "@c/ContentEditor.vue";

export default {
    name: "News",
    components: {
        ContentEditor,
        FileUploader
    },
    data() {
        return {
            query: '',
            dialogSource: false,
            onlyHidden: false,
            list: [],
            cities: [],
            tags: [],
            user: 0,
            datePubDialog: false,
            page: 1,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['id']
            },
            headers: [
                {
                    value: "id",
                    text: "ID",
                    sortable: false,
                },
                {
                    value: "name",
                    text: "Заголовок",
                    sortable: false,
                },
                {
                    value: "tags_string",
                    text: "Категории",
                    sortable: false,
                },
                {
                    value: "city_string",
                    text: "Города",
                    sortable: false,
                },
                {
                    value: "datePub",
                    text: "Дата",
                    sortable: false,
                },
                {
                    value: "views",
                    text: "Просмотры",
                    sortable: false,
                },
                {
                    value: "postpone",
                    text: "Отложена",
                    sortable: false,
                },
                {
                    value: "user_string",
                    text: "Пользователь",
                    sortable: false,
                },
                {
                    value: "indexShow",
                    text: "Вывести на главной",
                    sortable: false,
                },
                {
                    value: "hide",
                    text: "Скрыть?",
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pg: 1,
            totalCount: -1,
            hasNext: false,
            dialogEdit: false,
            defaultItem: {},
            defaultSource: {},
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                description: '',
                tags: [],
                preview: '',
                newPreview: '',
                datePub: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                timezone: 'Asia/Krasnoyarsk',
                postpone: false,
                cities: [],
                userId: -1,
                hide: false,
                source: {
                    text: '',
                    link: ''
                },
                content: [],
                indexShow: false,
                isTop: false
            },
            dialogRemove: false,
            itemToRemove: -1,
            curTab: null
        };
    },
    watch: {
        options(pagination) {
            this.page = pagination.page;
            this.getList(pagination.page);
        },
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        this.defaultItem = Object.assign({}, this.editedItem);
        this.getCities();
        this.getTags();
        this.getUser();
    },
    computed: {
        getPostponeDate () {
            return this.editedItem.datePub.toLocaleString(['ru-RU'], {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        },
        formTitle () {
            return this.editedIndex === -1 ? 'Новая новость' : 'Редактирование новости #' + this.editedItem.id;
        },
    },
    methods: {
        async search () {
            await this.getList();
        },
        onOpenSource () {
            this.defaultSource = Object.assign({}, this.editedItem.source);
        },
        onCancelSource () {
            this.editedItem.source = Object.assign({}, this.defaultSource);
            this.dialogSource = false;
        },
        resetDate() {
            if (!this.editedItem.postpone) {
                this.editedItem.datePub = this.defaultItem.datePub;
            }
        },
        async getList(pg = 1) {
            this.loading = true;
            try {
                const { list, hasNext, totalCount } = await this.$api(`/news?${new URLSearchParams({
                    pg: pg,
                    on: this.options.itemsPerPage,
                    hide: this.onlyHidden,
                    withoutPostpone: false,
                    fromAdmin: true,
                    query: this.query
                })}`);

                list.forEach((singleNew, index) => {
                    let city_names = [];

                    singleNew.cities.forEach((city) => {
                        city_names.push(city.name);
                    });

                    list[index].city_string = city_names.join(', ');

                    let tag_names = [];

                    singleNew.tags.forEach((tag) => {
                        tag_names.push(tag.name);
                    });

                    list[index].tags_string = tag_names.join(', ');

                    list[index].user_string = "[" + singleNew.user.id + "] " + singleNew.user.login;
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pg = pg;
                this.totalCount = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getCities() {
            try {
                const { list } = await this.$api("/cities");

                this.cities = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getTags() {
            try {
                const { list } = await this.$api("/tags");

                this.tags = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getUser() {
            try {
                let userId = await this.$api("/current");
                this.user = userId.id;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            this.defaultItem = Object.assign({}, this.editedItem);
            try {
                let r = await this.$api(`/news/${item.id}`);
                this.editedItem = Object.assign({}, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api(`/admin/news/${item.id}`, {
                    method: "DELETE"
                });
                if (!r.error) {
                    this.getList();
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem, {
                    content: [],
                    source: {
                        text: '',
                        link: ''
                    }
                });
                this.editedIndex = -1;
            });
        },
        async uploadPreview() {
            if (this.editedItem.newPreview) {
                const needUpload = [];

                this.editedItem.newPreview.id = this.editedItem.id;
                needUpload.push(this.editedItem.newPreview);
                delete this.editedItem.newPreview;

                const files = await this.$refs.uploader.upload(needUpload);

                for (let f of files) {
                    if (f.id == this.editedItem.id) {
                        let fileData = {
                            tmp: f.tmp,
                            name: f.name,
                            size: Math.ceil(f.size / 1024 / 1024),
                            type: f.type.split('/')[1]
                        };
                        return fileData;
                    }
                }
            }
            return false;
        },
        async uploadContent() {
            if (this.editedItem.content) {
                for (let block of this.editedItem.content) {
                    const needUpload = []; 
                    let value = null;
                    let imageText = null;

                    if (block.id < 0) {
                        switch (parseInt(block.type)) {
                            case 5:
                            case 6:
                                value = block.data.value.newValue ?? block.data.value.file;
                                if (block.type == 5) {
                                    if (block.data.value.text) {
                                        imageText = block.data.value.text;
                                    } 
                                }
                                
                                // если нового файла нет, то пропускаем загрузку
                                if (!(value instanceof File)) {
                                    break;
                                }
                                value.blockType = block.type;
                                needUpload.push(value);
                                delete block.data.value;
                                delete block.data.newValue;
                                break;
                            case 7:
                            case 8:
                                block.data.value.list.map((item, index) => {
                                    item.blockType = block.type;
                                    item.id = index;
                                    
                                    if (!(item instanceof File)) {
                                        return;
                                    }
                                    needUpload.push(item);
                                });
                                // если новоых файлов нет, то пропускаем загрузку
                                if (needUpload.length < 1) {
                                    break;
                                }
                                
                                delete block.data.value.list;
                                break;
                        }
                    }

                    if (needUpload.length > 0) {
                        const files = await this.$refs.uploader.upload(needUpload);
                        for (let f of files) {
                            let fileData = null;
                            if (f.blockType == 5) {
                                fileData = {
                                    file: {
                                        tmp: f.tmp,
                                        name: f.name,
                                        size: Math.ceil(f.size / 1024 / 1024),
                                        type: f.type.split('/')[1]
                                    },
                                    text: imageText
                                };
                            } else {
                                fileData = {
                                    tmp: f.tmp,
                                    name: f.name,
                                    size: Math.ceil(f.size / 1024 / 1024),
                                    type: f.type.split('/')[1]
                                };
                            }
                            
                            if (f.blockType == 7 || f.blockType == 8) {
                                if (!Object.hasOwnProperty.call(block.data.value, 'list')) {
                                    block.data.value.list = [];
                                }
                                block.data.value.list.push(fileData);
                            } else {
                                block.data.value = fileData;
                            }
                            
                        }
                    }
                }
            }
            return false;
        },
        async save () {
            if (!this.editedItem.name) {
                this.$error('Поле "заголовок" обязательно');
                return;
            }
            if (!this.editedItem.description) {
                this.$error('Поле "краткое описание" обязательно');
                return;
            }
            if (this.editedItem.tags.length < 1) {
                this.$error('Поле "категории" обязательно');
                return;
            }
            if ((this.editedItem.source.text && !this.editedItem.source.link)) {
                this.$error('Укажите ссылку на источник');
                return;
            }
            if ((!this.editedItem.source.text && this.editedItem.source.link)) {
                this.$error('Укажите текст источника');
                return;
            }
            
            if (this.editedItem.cities.length < 1) {
                this.$error('Поле "города" обязательно');
                return;
            }
            if (this.editedItem.content.length < 1) {
                this.$error('Контент не может быть пустым');
                return;
            }

            let previewFile = [];
            if (this.editedItem.newPreview) {
                previewFile = await this.uploadPreview();
            }
            await this.uploadContent();
            let c_ids = []; 
            this.editedItem.cities.forEach((city) => {
                if (city.id) {
                    c_ids.push(city.id);
                } else {
                    c_ids.push(city);
                }
            });

            let t_ids = []; 
            this.editedItem.tags.forEach((tag) => {
                if (tag.id) {
                    t_ids.push(tag.id);
                } else {
                    t_ids.push(tag);
                }
            });
            
            this.editedItem.content.forEach(item => {
                if (item.type == 9) {
                    if (item.data.value.answers.length < 1) {
                        throw this.$error(`У Вас пустой опрос. "${item.data.value.title}"`);
                    }
                }
            });
            
            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/news/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            name: this.editedItem.name,
                            description: this.editedItem.description,
                            source: this.editedItem.source,
                            postpone: this.editedItem.postpone ? 1 : 0,
                            datePub: this.editedItem.datePub,
                            city_ids: c_ids,
                            tag_ids: t_ids,
                            hide: this.editedItem.hide,
                            user_id: this.user,
                            poster: previewFile.tmp ?? [],
                            content: this.editedItem.content,
                            indexShow: this.editedItem.indexShow,
                            isTop: this.editedItem.isTop
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api("/admin/news/add", {
                        method: "POST",
                        body: {
                            name: this.editedItem.name,
                            description: this.editedItem.description,
                            source: this.editedItem.source,
                            postpone: this.editedItem.postpone ? 1 : 0,
                            datePub: this.editedItem.datePub,
                            city_ids: c_ids,
                            tag_ids: t_ids,
                            hide: this.editedItem.hide,
                            user_id: this.user,
                            poster: previewFile.tmp ?? [],
                            content: this.editedItem.content,
                            indexShow: this.editedItem.indexShow,
                            isTop: this.editedItem.isTop
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        }
    }
};
</script>

<style scoped>
    .date-picker {
        border: none;
    }
</style>